import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { validateEmail, validateNotEmpty } from 'svr/common/Validators'
import PhoneNumber from 'svr/component-lib/Widget/PhoneNumber'
import TextInput from 'svr/lib/TextInputs/TextInput'
import { changeFlag, changeFormField, changePhoneNum, passCardElement } from 'widget/paylink/actions/forms'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import styles from '../assets/styles/checkout'

class CheckoutPaymentBaseFields extends Component {
  handleFirstNameChange = value => {
    this.props.changeFormField('cardFirstName', value)
  }

  handleLastNameChange = value => {
    this.props.changeFormField('cardLastName', value)
  }

  handleEmailChange = value => {
    this.props.changeFormField('email', value)
  }

  handlePhoneNumberChange = (phoneNumber, dialCode) => {
    this.props.changePhoneNum(phoneNumber, this.props.phoneNumberLocale, dialCode)
  }

  handleFlagChange = (number, locale) => {
    this.props.changeFlag(number, locale)
  }

  renderBaseFields() {
    const {
      cardFirstName,
      cardLastName,
      email,
      fontsColorPrimary,
      fontsColorCheckoutInactive,
      fontsColorCheckoutActive,
      colorError,
      colorCheckoutCellBackground,
      colorLines,
      paymentFields,
      formErrors,
      fontFamily,
      textFirstName,
      textLastName,
      textWidgetEmailLabel,
      dialCode,
      phoneNumberLocale,
      phoneNumber,
      textPhoneNumber,
      isPhoneNeeded,
      disabled,
    } = this.props
    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    return (
      <div style={[styles.sectionWrapper, { paddingTop: 0, pointerEvents: disabled ? 'none' : 'auto' }]}>
        <div style={styles.labelWrapper}>
          <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>
            {textFirstName} / {textLastName} / {textWidgetEmailLabel}
          </span>
        </div>
        <div style={styles.infoForm}>
          <div style={[styles.formLine, styles.topBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
            <div style={styles.formBox}>
              <TextInput
                placeholder={`${textFirstName} *`}
                limitType="name"
                value={cardFirstName}
                validator={validateNotEmpty}
                isValid={!formErrors.cardFirstName}
                onChange={this.handleFirstNameChange}
                style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
                ref={input => {
                  paymentFields.cardFirstName = input
                }}
                {...textInputProps}
              />
            </div>
            <div style={[styles.formBox, styles.padLeft]}>
              <TextInput
                placeholder={`${textLastName} *`}
                limitType="name"
                value={cardLastName}
                validator={validateNotEmpty}
                isValid={!formErrors.cardLastName}
                onChange={this.handleLastNameChange}
                style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
                ref={input => {
                  paymentFields.cardLastName = input
                }}
                {...textInputProps}
              />
            </div>
          </div>
          <div>
            <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
              <div style={styles.formBox}>
                <TextInput
                  placeholder={`${textWidgetEmailLabel} *`}
                  limitType="email"
                  value={email}
                  validator={validateEmail}
                  isValid={!formErrors.email}
                  onChange={this.handleEmailChange}
                  style={styles.singleFieldInput}
                  ref={input => {
                    paymentFields.email = input
                  }}
                  {...textInputProps}
                />
              </div>
            </div>
          </div>
          {isPhoneNeeded && (
            <div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
                <div style={styles.formBox}>
                  <PhoneNumber
                    onPhoneNumberChange={this.handlePhoneNumberChange}
                    onFlagChange={this.handleFlagChange}
                    customPhoneNumberWrapper={[styles.singleFieldInput]}
                    dialCode={dialCode}
                    fontsColorCheckoutActive={fontsColorCheckoutActive}
                    fontsColorCheckoutInactive={fontsColorCheckoutInactive}
                    fontFamily={fontFamily}
                    isValid={!formErrors.phoneNumber}
                    phoneNumber={phoneNumber}
                    selectedCountryCode={phoneNumberLocale}
                    placeholderText={textPhoneNumber}
                    autoComplete="random-bits-of-words-to-break-autocomplete"
                    ariaLabel="Phone Number"
                    ref={input => {
                      paymentFields.phoneNumber = input
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    return <div>{this.renderBaseFields()}</div>
  }
}

const mapStateToProps = state => {
  const { formFields, widgetSettings } = state
  const languageStrings = selectLanguageStrings(state)

  return {
    fontsColorPrimary: widgetSettings.fontsColorPrimary,
    colorError: widgetSettings.colorError,
    textPayment: languageStrings.textPayment,

    cardFirstName: formFields.get('cardFirstName'),
    cardLastName: formFields.get('cardLastName'),
    cardNum: formFields.get('cardNum'),
    cardMonthExp: formFields.get('cardMonthExp'),
    cardYearExp: formFields.get('cardYearExp'),
    cardCvv: formFields.get('cardCvv'),
    email: formFields.get('email'),
    zipCode: formFields.get('zipCode'),
    requirePayment: !!state.billingInfo.amount,
    formErrors: formFields.get('formErrors').toJS(),
    fontFamily: state.widgetSettings.font,
    fontsColorCheckoutInactive: widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: widgetSettings.fontsColorCheckoutActive,
    colorCheckoutCellBackground: widgetSettings.colorCheckoutCellBackground,
    colorLines: widgetSettings.colorLines,
    mediaUrl: widgetSettings.mediaUrl,
    selectedLanguage: state.languages.selectedLanguage,
    dialCode: formFields.get('dialCode'),
    phoneNumberLocale: formFields.get('phoneNumberLocale'),
    phoneNumber: formFields.get('phoneNumber'),
    // text
    textFirstName: languageStrings.textFirstName,
    textLastName: languageStrings.textLastName,
    textCreditCardNumber: languageStrings.textCreditCardNumber,
    textCvv: languageStrings.textCvv,
    textWidgetEmailLabel: languageStrings.textWidgetEmailLabel,
    textCreditCardExpMonthLabel: languageStrings.textCreditCardExpMonthLabel,
    textCreditCardExpYearLabel: languageStrings.textCreditCardExpYearLabel,
    textCreditCardZipcode: languageStrings.textCreditCardZipcode,
    textPhoneNumber: languageStrings.textPhoneNumber,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  passCardElement: element => {
    dispatch(passCardElement(element))
  },
  changeFlag: (changeTo, selectedCountry) => {
    dispatch(changeFlag(changeTo, selectedCountry))
  },
  changePhoneNum: (changeTo, selectedCountry, dialCode) => {
    dispatch(changePhoneNum(changeTo, selectedCountry, dialCode))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutPaymentBaseFields))
