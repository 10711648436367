import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { AdyenCheckout, Dropin } from '@adyen/adyen-web/auto'
import '@adyen/adyen-web/styles/adyen.css'
import _ from 'lodash'
import { fetchAdyenInitialData, submitPaymentDetails } from 'svr/component-lib/Widget/Payments/Adyen/services'
import { connect } from 'react-redux'
import styled from 'styled-components'

class AdyenFormRender extends Component {
  constructor(props) {
    super(props)
    this.adyenRootCard = React.createRef()

    this.state = {
      adyenCheckout: null,
      dropIn: null,
      paymentType: null,
      paymentData: null,
      actions: null,
    }
  }

  componentDidMount() {
    const onSubmitPayment = this.props.onSubmit
    const { paymentType } = this.state
    const theme = { ...this.props.infoForm, ...this.props.widgetTheme }
    const baseInputStyle = {
      base: {
        color: theme?.fontsColorPrimary,
        fontFamily: theme?.fontFamily,
        fontSize: theme?.fontSize?.body,
        fontWeight: 'normal',
      },
    }

    const configuration = {
      paymentMethodsResponse: this.props.adyenInitialData.payment_methods.message,
      clientKey: this.props.adyenInitialData.client_key,
      locale: this.props.adyenInitialData.locale,
      environment: this.props.adyenInitialData.environment,
      countryCode: this.props.countryCode,
      showPayButton: false,
      onChange: state => {
        this.props.onValidate(state.isValid)
      },
      onSubmit(state) {
        let paymentData = state.data
        if (paymentType === 'paypal') {
          paymentData = { paymentMethod: { type: paymentType } }
        }

        onSubmitPayment(paymentData)
      },
      onAdditionalDetails: (state, component, actions) => {
        const { actualId, bookingFrom, historyId, paylinkHistoryId } = this.props.dataForPendingPayment
        submitPaymentDetails({
          venueId: this.props.venueId,
          paymentData: state.data,
          actualId,
          bookingFrom,
          historyId,
          paylinkHistoryId,
        }).then(additionalDetailsResponse => {
          if (additionalDetailsResponse.resultCode === 'Authorised') {
            component.setStatus('success', { message: 'Payment successful!' })
            this.props.onSuccess()
          } else {
            actions?.reject({})
            this.props.onFailure(additionalDetailsResponse?.errorMessage)
          }
        })
      },
      onError: error => {
        this.props.onFailure(error)
      },
    }
    const { amount, currencyCode } = this.props
    const dropInConfiguration = {
      onSelect: paymentTypeElement => {
        const paymentType = paymentTypeElement.props.type
        const { isValid } = paymentTypeElement
        this.setState(prevState => ({ ...prevState, paymentType }))
        this.props.onValidate(isValid)
        this.props.onChangePaymentMethod(paymentType)
      },
      paymentMethodsConfiguration: {
        googlepay: {
          amount: { value: amount, currency: currencyCode },
        },
        applepay: {
          amount: { value: amount, currency: currencyCode },
        },
        card: {
          styles: baseInputStyle,
        },
        bcmc: {
          styles: baseInputStyle,
        },
        sepadirectdebit: {
          styles: baseInputStyle,
        },
      },
      showPayButton: false,
    }

    AdyenCheckout(configuration).then(checkout => {
      try {
        const dropIn = new Dropin(checkout, dropInConfiguration).mount(this.adyenRootCard.current)
        this.setState({
          adyenCheckout: checkout,
        })
        this.props.setAdyenCheckout({
          checkout,
          dropIn,
        })
      } catch (e) {
        this.props.onFailure(e)
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.dataFor3Dsecure) && !_.isEmpty(nextProps.dataFor3Dsecure)) {
      this.state.adyenCheckout
        .createFromAction(nextProps.dataFor3Dsecure, { redirectFromTopWhenInIframe: true })
        .mount(this.adyenRootCard.current)
    }
  }

  render() {
    const { infoForm, widgetTheme } = this.props
    return (
      <>
        <div style={infoForm}>
          <StyledForm ref={this.adyenRootCard} widgetTheme={{ ...widgetTheme, ...infoForm }} />
        </div>
      </>
    )
  }
}

AdyenFormRender.propTypes = {
  venueId: PropTypes.string,
  adyenInitialData: PropTypes.object,
  dataFor3Dsecure: PropTypes.object,
  onSubmit: PropTypes.func,
  setAdyenCheckout: PropTypes.func,
  onValidate: PropTypes.func,
  infoForm: PropTypes.object,
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  amount: PropTypes.number,
  dataForPendingPayment: PropTypes.object,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onChangePaymentMethod: PropTypes.func,
}

class AdyenFormFetch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adyenInitialData: null,
    }
  }

  componentDidMount() {
    fetchAdyenInitialData(this.props.venueId).then(r =>
      this.setState({
        adyenInitialData: r,
      })
    )
  }

  render() {
    if (!this.state.adyenInitialData?.client_key || this.state.adyenInitialData?.needClean) {
      return null
    }
    return (
      <>
        <AdyenFormRender
          key={this.props.venueId}
          venueId={this.props.venueId}
          adyenInitialData={this.state.adyenInitialData}
          dataFor3Dsecure={this.props.dataFor3Dsecure}
          infoForm={this.props.infoForm}
          onSubmit={this.props.onSubmit}
          setAdyenCheckout={this.props.setAdyenCheckout}
          onValidate={this.props.onValidate}
          mediaUrl={this.props.mediaUrl}
          countryCode={this.props.countryCode}
          widgetTheme={this.props.widgetTheme}
          currencyCode={this.props.currencyCode}
          amount={this.props.amount}
          dataForPendingPayment={this.props.dataForPendingPayment}
          onSuccess={this.props.onSuccess}
          onFailure={this.props.onFailure}
          onChangePaymentMethod={this.props.onChangePaymentMethod}
        />
      </>
    )
  }
}

AdyenFormFetch.propTypes = {
  venueId: PropTypes.string,
  dataFor3Dsecure: PropTypes.object,
  dataForPendingPayment: PropTypes.object,
  onSubmit: PropTypes.func,
  setAdyenCheckout: PropTypes.func,
  onValidate: PropTypes.func,
  onChangePaymentMethod: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  mediaUrl: PropTypes.string,
  countryCode: PropTypes.string,
  amount: PropTypes.number,
}

const mapStateToProps = state => ({
  mediaUrl: state.widgetSettings.mediaUrl,
  countryCode: state.venueInfo.countryCode?.toUpperCase() || state.venueInfo?.municipality?.countryCode?.toUpperCase(),
  widgetTheme: state.theme,
  currencyCode: state.venueInfo.currencyCode,
  dataForPendingPayment: state.commonPayment.pendingPayment,
})

const StyledForm = styled.div`
  .adyen-checkout__dropin,
  .adyen-checkout__field--expiryDate,
  .adyen-checkout__field__cvc,
  .adyen-checkout__card__holderName,
  .adyen-checkout__dropdown__element,
  .adyen-checkout__dropdown__button,
  .adyen-checkout__payment-method__header__title,
  .adyen-checkout__label__text,
  .adyen-checkout__payment-method__name .adyen-checkout__input::placeholder {
    color: ${props => props.widgetTheme?.fontsColorPrimary};
    font-family: ${props => props.widgetTheme?.fontFamily};
    font-size: ${props => props.widgetTheme?.fontSize?.body};
    font-weight: normal;
  }

  .adyen-checkout__button__text {
    font-size: ${props => props.widgetTheme?.fontSize?.body};
  }

  .adyen-checkout__payment-method__name--selected {
    font-weight: bold;
  }

  .adyen-checkout__input {
    border: none !important;
  }

  .adyen-checkout__payment-method {
    background: ${props => props.widgetTheme?.colorCheckoutCellBackground};
    border: none;
    border-radius: 0;
  }

  .adyen-checkout__payment-method:not(:first-child) {
    border-top: 1px solid ${props => props.widgetTheme?.colorLines};
  }

  .adyen-checkout__label__text {
    padding: 0 !important;
  }

  .adyen-checkout__input-wrapper,
  .adyen-checkout-contextual-text {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .adyen-checkout-contextual-text--hidden {
    margin-bottom: 0 !important;
  }

  .adyen-checkout__dropdown__button--active,
  .adyen-checkout__dropdown__button--active:hover,
  .adyen-checkout__dropdown__button:active,
  .adyen-checkout__dropdown__button:focus,
  .adyen-checkout__dropdown__button:hover,
  .adyen-checkout__payment-method--selected .adyen-checkout__payment-method__image__wrapper {
    border: 1px solid ${props => props.widgetTheme?.colorPrimary} !important;
    box-shadow: none;
  }

  .adyen-checkout__payment-method--selected .adyen-checkout__payment-method__image__wrapper {
    transform: scale(1.2);
  }

  .adyen-checkout__payment-method__image__wrapper {
    border: 1px solid ${props => props.widgetTheme?.colorLines};
  }

  .adyen-checkout__payment-methods-list {
    gap: 0;
  }

  .adyen-checkout__payment-method:first-child {
    border-top-right-radius: ${props => props.widgetTheme.borderRadius};
    border-top-left-radius: ${props => props.widgetTheme.borderRadius};
  }

  .adyen-checkout__payment-method:last-child {
    border-bottom-right-radius: ${props => props.widgetTheme.borderRadius};
    border-bottom-left-radius: ${props => props.widgetTheme.borderRadius};
  }

  .adyen-checkout__dropdown__button,
  .adyen-checkout__input {
    border-radius: ${props => props.widgetTheme.borderRadius};
  }

  .adyen-checkout__dropdown__button,
  .adyen-checkout__input,
  .adyen-checkout__dropdown__button--valid,
  .adyen-checkout__input--valid {
    border: 1px solid ${props => props.widgetTheme?.colorLines};
  }

  .adyen-checkout__input--error,
  .adyen-checkout__error-text {
    border-color: ${props => props.widgetTheme?.colorError};
  }

  .adyen-checkout-contextual-text--error {
    margin-bottom: 12px;
  }

  .adyen-checkout__payment-method--selected {
    margin: 0;
    background: ${props => props.widgetTheme?.colorCellBackground || props.widgetTheme?.colorBackground};
    border: 1px solid ${props => props.widgetTheme?.colorPrimary} !important;
  }

  .adyen-checkout__payment-method__details__content {
    margin: 0;
    background: ${props => props.widgetTheme?.colorCellBackground || props.widgetTheme?.colorBackground};
    border: none;
  }

  // requirement from the design team to hide form instructions and validation icons

  .adyen-checkout-form-instruction,
  .adyen-checkout-input__inline-validation {
    display: none;
  }

  fieldset {
    padding: 0;
  }

  .adyen-checkout__dropdown__element,
  .adyen-checkout__filter-input {
    text-transform: capitalize;
  }
`

export default connect(mapStateToProps)(AdyenFormFetch)
