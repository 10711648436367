import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddressInput from 'svr/component-lib/Generic/TextInputs/AddressInput'
import { handleNetworkPayment, isNetworkRedux } from 'svr/lib/Payments/NetworkRedux'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import {
  validateNotEmpty,
  validateCardMonthExp,
  validateCardYearExp,
  validateCardCvv,
  validateEmail,
  validateEmailOptional,
} from '../../../../common/Validators'
import { AccountTypes } from '../../../../lib/Payments/Constants'
import CardNumberInput from '../../../../lib/TextInputs/CardNumberInput'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { changeFormField, passCardElement } from '../actions/forms'
import { initializationFailure, postCheckoutFailure } from '../actions/services'
import styles from '../assets/styles/checkout'
import { StripePaymentElementFields } from '@sevenrooms/payments'

class CheckoutPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: null,
    }
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'cardFirstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'cardLastName')
    this.handleEmailChange = this.props.changeFormField.bind(this, 'email')
    this.handleCardNumChange = this.props.changeFormField.bind(this, 'cardNum')
    this.handleMonthExpChange = this.props.changeFormField.bind(this, 'cardMonthExp')
    this.handleYearExpChange = this.props.changeFormField.bind(this, 'cardYearExp')
    this.handleCvvChange = this.props.changeFormField.bind(this, 'cardCvv')
    this.handleZipCodeChange = this.props.changeFormField.bind(this, 'zipCode')
    this.mediaUrl = this.props.mediaUrl
  }

  handleAddressChange = (address, place) => {
    const address1 = []
    let locality
    let administrativeArea
    let postalCode
    let countryCode
    if (!place) {
      this.setState({ address: '' })
      this.props.changeFormField('address1', null)
      this.props.changeFormField('locality', null)
      this.props.changeFormField('administrativeArea', null)
      this.props.changeFormField('countryCode', null)
      this.props.changeFormField('postalCode', null)
      return
    }
    // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
    _.each(place.address_components, addressComponent => {
      if (['street_address', 'street_number', 'route'].some(r => addressComponent.types.includes(r))) {
        address1.push(addressComponent.long_name)
      }
      if (addressComponent.types.includes('locality')) {
        locality = addressComponent.long_name
      }
      if (!locality && addressComponent.types.includes('administrative_area_level_1')) {
        locality = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        administrativeArea = addressComponent.short_name
      }
      if (addressComponent.types.includes('country')) {
        countryCode = addressComponent.short_name
      }
      if (addressComponent.types.includes('postal_code')) {
        postalCode = addressComponent.long_name
      }
    })
    this.setState({
      address,
    })
    this.props.changeFormField('address1', address1.join(' '))
    this.props.changeFormField('locality', locality)
    this.props.changeFormField('administrativeArea', administrativeArea)
    this.props.changeFormField('countryCode', countryCode)
    this.props.changeFormField('postalCode', postalCode)
  }

  componentDidMount() {
    const { baseUrl, mediaUrl, venueInfo } = this.props

    if (isNetworkRedux(venueInfo)) {
      window.NI.mountCardInput('ccFormMountId', {
        style: {
          main: {
            margin: 0,
          },
          base: {
            borderStyle: 'solid',
            borderWidth: '0 0 1px 0',
            borderColor: '#eee',
            fontFamily: 'Roboto',
            fontUrl: `${baseUrl}${mediaUrl}css/fonts.css`,
            fontSize: '14px',
          },
          input: {
            color: '#000',
            margin: 0,
            padding: '10px 10px 10px 17px',
          },
        },
        // API Key for WEB SDK from the portal; the
        // fail string is needed to prod the SDK into
        // triggering its own onFail method. smh.
        apiKey: venueInfo.paymentGatewayApiKey || 'fail',

        // outlet reference from the portal
        outletRef: venueInfo.accountId,

        // These apply to api validation
        onSuccess: () => {},
        onFail: element => this.props.initializationFailure(element, this.props.textCommonPaymentErrorConnectionError),

        onChangeValidStatus: ({ isCVVValid, isExpiryValid, isNameValid, isPanValid }) => {},
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.networkReduxResponse) && !_.isEmpty(nextProps.networkReduxResponse)) {
      this.props.handleNetworkPayment(nextProps.networkReduxResponse, postCheckoutFailure)
    }

    const { venueInfo } = this.props
    if (isNetworkRedux(venueInfo) && _.isEmpty(this.props.paymentPendingResponse) && !_.isEmpty(nextProps.paymentPendingResponse)) {
      this.props.handleNetworkPayment(nextProps.paymentPendingResponse, postCheckoutFailure)
    }
  }

  render() {
    const {
      venueInfo,
      cardFirstName,
      cardLastName,
      cardNum,
      cardMonthExp,
      cardYearExp,
      cardCvv,
      zipCode,
      email,
      fontsColorCheckoutInactive,
      fontsColorCheckoutActive,
      colorPrimary,
      colorError,
      colorCheckoutCellBackground,
      colorLines,
      paymentFields,
      topElementId,
      formErrors,
      fontFamily,
      baseUrl,
      mediaUrl,
      requirePayment,
      textFirstName,
      textLastName,
      textCreditCardNumber,
      textCvv,
      textWidgetEmailLabel,
      textCreditCardExpMonthLabel,
      textCreditCardExpYearLabel,
      textCreditCardExpYearDefaultLabel,
      textCreditCardZipcode,
      selectedLanguage,
      stripeIntentClientSecret,
    } = this.props
    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    const boxStyle = venueInfo.needsZipCode ? styles.smallFormFourBox : styles.smallFormThreeBox

    const isValidAddress = !this.props.submitClicked || this.state.address

    const yearPlaceholder = [AccountTypes.CYBERSOURCE, AccountTypes.CYBERSOURCE_REDUX, AccountTypes.CYBERSOURCE_3DS_REDUX].includes(
      venueInfo.paymentType
    )
      ? textCreditCardExpYearLabel
      : textCreditCardExpYearDefaultLabel

    return (
      <div name={topElementId} id={topElementId} style={styles.sectionWrapper}>
        <div style={styles.infoForm}>
          <div style={[styles.formLine, styles.topBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
            <div style={styles.formBox}>
              <TextInput
                placeholder={`${textFirstName} *`}
                limitType="name"
                value={cardFirstName}
                validator={validateNotEmpty}
                isValid={!formErrors.cardFirstName}
                onChange={this.handleFirstNameChange}
                style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
                ref={input => {
                  paymentFields.cardFirstName = input
                }}
                {...textInputProps}
              />
            </div>
            <div style={[styles.formBox, styles.padLeft]}>
              <TextInput
                placeholder={`${textLastName} *`}
                limitType="name"
                value={cardLastName}
                validator={validateNotEmpty}
                isValid={!formErrors.cardLastName}
                onChange={this.handleLastNameChange}
                style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
                ref={input => {
                  paymentFields.cardLastName = input
                }}
                {...textInputProps}
              />
            </div>
          </div>

          <div>
            <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
              <div style={styles.formBox}>
                <TextInput
                  placeholder={`${textWidgetEmailLabel}${requirePayment ? ' *' : ''}`}
                  limitType="email"
                  value={email}
                  validator={requirePayment ? validateEmail : validateEmailOptional}
                  isValid={!formErrors.email}
                  onChange={this.handleEmailChange}
                  style={styles.singleFieldInput}
                  ref={input => {
                    paymentFields.email = input
                  }}
                  {...textInputProps}
                />
              </div>
            </div>
          </div>

          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />

          {venueInfo.paymentType === AccountTypes.STRIPE ? (
            <div
              style={{
                padding: styles.stripeIframe.padding,
              }}
            >
              {' '}
              <StripePaymentElementFields
                stripe={this.props.stripe || null}
                clientSecret={stripeIntentClientSecret}
                locale={selectedLanguage}
                fonts={`${baseUrl}${mediaUrl}css/fonts.css`}
                appearance={{
                  variables: {
                    fontFamily: `${fontFamily} !important`,
                    fontSizeBase: styles.stripeIframe.fontSizeBase,
                    fontSizeSm: styles.stripeIframe.fontSizeBase,
                    fontSizeXs: styles.stripeIframe.fontSize,
                    colorPrimary,
                    colorTextPlaceholder: fontsColorCheckoutInactive,
                    colorTextSecondary: fontsColorCheckoutActive,
                    colorText: styles.stripeIframe.color,
                  },
                  rules: {
                    '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
                      backgroundColor: 'transparent',
                      border: `1px solid ${styles.formLineSeperator.color}`,
                    },
                    '.TabLabel': {
                      fontSize: styles.stripeIframe.fontSizeBase,
                      fontWeight: 'normal',
                    },
                    '.Tab--selected': {
                      boxShadow: `0 0 1px 1px ${colorPrimary}`,
                    },
                    '.TabLabel--selected': {
                      color: colorPrimary,
                    },
                    '.TabIcon--selected': {
                      color: colorPrimary,
                    },
                  },
                }}
              />
            </div>
          ) : null}

          {isNetworkRedux(venueInfo) ? (
            <div>
              <div id="ccFormMountId" style={{ height: '210px' }} />
              <div id="cc3dsMountId">
                <div id="loadingIndicator" />
              </div>
            </div>
          ) : null}

          {![AccountTypes.STRIPE, AccountTypes.NETWORK_REDUX, AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE].includes(
            venueInfo.paymentType
          ) ? (
            <div>
              <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
                <div style={styles.formBox}>
                  <CardNumberInput
                    placeholder={`${textCreditCardNumber} *`}
                    value={cardNum}
                    isValid={!formErrors.cardNum}
                    onChange={this.handleCardNumChange}
                    style={styles.singleFieldInput}
                    mediaUrl={this.mediaUrl}
                    ref={input => {
                      paymentFields.cardNum = input
                    }}
                    inputId="sr-card-number-*"
                    {...textInputProps}
                  />
                </div>
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div style={[styles.formLine, styles.bottomBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
                <div style={[styles.formBox, boxStyle]}>
                  <TextInput
                    placeholder={`${textCreditCardExpMonthLabel} *`}
                    limitType="number"
                    charLimit={2}
                    value={cardMonthExp}
                    validator={validateCardMonthExp}
                    isValid={!formErrors.cardMonthExp}
                    onChange={this.handleMonthExpChange}
                    textColor={this.props.fontsColorPrimary}
                    style={styles.thirdBox}
                    ref={input => {
                      paymentFields.cardMonthExp = input
                    }}
                    inputId="sr-mm-*"
                    {...textInputProps}
                  />
                </div>
                <div style={[styles.formBox, boxStyle, styles.padLeft]}>
                  <TextInput
                    placeholder={`${yearPlaceholder} *`}
                    limitType="number"
                    charLimit={4}
                    value={cardYearExp}
                    validator={validateCardYearExp}
                    isValid={!formErrors.cardYearExp}
                    onChange={this.handleYearExpChange}
                    textColor={this.props.fontsColorPrimary}
                    style={styles.thirdBox}
                    ref={input => {
                      paymentFields.cardYearExp = input
                    }}
                    inputId="sr-yyyy-*"
                    {...textInputProps}
                  />
                </div>
                <div style={[styles.formBox, boxStyle, styles.padLeft]}>
                  <TextInput
                    placeholder={`${textCvv} *`}
                    limitType="number"
                    charLimit={4}
                    value={cardCvv}
                    validator={validateCardCvv}
                    isValid={!formErrors.cardCvv}
                    onChange={this.handleCvvChange}
                    textColor={this.props.fontsColorPrimary}
                    style={styles.thirdBox}
                    ref={input => {
                      paymentFields.cardCvv = input
                    }}
                    inputId="sr-cvc-*"
                    {...textInputProps}
                  />
                </div>
                {venueInfo.needsZipCode ? (
                  <div style={[styles.formBox, styles.padLeft]}>
                    <TextInput
                      placeholder={`${textCreditCardZipcode} *`}
                      charLimit={7}
                      value={zipCode}
                      validator={validateNotEmpty}
                      isValid={!formErrors.zipCode}
                      onChange={this.handleZipCodeChange}
                      textColor={this.props.fontsColorPrimary}
                      style={styles.thirdBox}
                      ref={input => {
                        paymentFields.zipCode = input
                      }}
                      inputId="sr-zipcode-*"
                      {...textInputProps}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {this.props.isCybersourceBillingAddressRequired ? (
            <>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div>
                <AddressInput
                  googlePlacesApiKey={widgetInit.googlePlacesApiKey}
                  placeholder="Enter your billing address"
                  showLabel={false}
                  value={this.state.address}
                  isValid={isValidAddress}
                  onChange={this.handleAddressChange}
                  testId="sr-input-address"
                  inputStyles={{ marginLeft: '7px' }}
                  placeholderColor="#252525"
                  errorColor="#d01a21"
                  hideBorder={isValidAddress}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { formFields, widgetSettings } = state
  const languageStrings = selectLanguageStrings(state)
  return {
    venueInfo: state.venueInfo,
    cardFirstName: formFields.get('cardFirstName'),
    cardLastName: formFields.get('cardLastName'),
    cardNum: formFields.get('cardNum'),
    cardMonthExp: formFields.get('cardMonthExp'),
    cardYearExp: formFields.get('cardYearExp'),
    cardCvv: formFields.get('cardCvv'),
    email: formFields.get('email'),
    zipCode: formFields.get('zipCode'),
    requirePayment: !!state.billingInfo.amount,
    formErrors: formFields.get('formErrors').toJS(),
    fontFamily: state.widgetSettings.font,
    fontsColorPrimary: widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: widgetSettings.fontsColorCheckoutActive,
    colorPrimary: widgetSettings.colorPrimary,
    colorError: widgetSettings.colorError,
    colorCheckoutCellBackground: widgetSettings.colorCheckoutCellBackground,
    colorLines: widgetSettings.colorLines,
    baseUrl: state.widgetSettings.baseUrl,
    mediaUrl: widgetSettings.mediaUrl,
    selectedLanguage: state.languages.selectedLanguage,
    // text
    textFirstName: languageStrings.textFirstName,
    textLastName: languageStrings.textLastName,
    textPaylinkPageCreditCardDetailsSubheader: languageStrings.textPaylinkPageCreditCardDetailsSubheader,
    textCreditCardNumber: languageStrings.textCreditCardNumber,
    textCvv: languageStrings.textCvv,
    textWidgetEmailLabel: languageStrings.textWidgetEmailLabel,
    textCreditCardExpMonthLabel: languageStrings.textCreditCardExpMonthLabel,
    textCreditCardExpYearLabel: languageStrings.textCreditCardExpYearLabel,
    textCreditCardExpYearDefaultLabel: languageStrings.textCreditCardExpYearDefaultLabel,
    textCreditCardZipcode: languageStrings.textCreditCardZipcode,
    networkReduxResponse: state.payment.networkReduxResponse,
    paymentPendingResponse: state.payment.paymentPendingResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  passCardElement: element => {
    dispatch(passCardElement(element))
  },
  initializationFailure: (element, textCommonPaymentErrorConnectionError) => {
    dispatch(initializationFailure(element, textCommonPaymentErrorConnectionError))
  },
  handleNetworkPayment: (response, postCheckoutFailure) => {
    dispatch(handleNetworkPayment(response, postCheckoutFailure))
  },
})

CheckoutPayment = connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutPayment))

export default CheckoutPayment
