import { Map, List } from 'immutable'
import {
  DISPLAY_MODAL,
  DISMISS_MODAL,
  STRIPE_ERROR,
  TRY_POST_CHECKOUT,
  HIDE_LOADING,
  DISABLE_INPUTS_AND_SUBMIT,
  POST_CHECKOUT_SUCCESS,
  POST_CHECKOUT_FAILURE,
  POST_CHECKOUT_RELOAD_ON_FAILURE,
  ADVANCE_STAGE,
  REVERT_STAGE,
  TRY_AGAIN,
  PASS_STRIPE_CARD_ELEMENT,
} from '../actions/ActionTypes'
import { modalTypes, viewTypes } from '../utils/constantTypes'

let _stage = 0
if (widgetInit.successBypass || widgetInit.billingInfo.payment_successful) {
  _stage = 1
} else if (widgetInit.errorDisplay) {
  _stage = 2
}

const initialState = Map({
  displayModalType: null,
  modalMessage: '',
  stage: _stage,
  additionalDatesCounter: 0,
  viewSequence: List([viewTypes.CHECKOUT, viewTypes.CONFIRMATION, viewTypes.ERROR]),
  disableInputsAndSubmit: false,
})

const ui = (state = initialState, action) => {
  switch (action.type) {
    case ADVANCE_STAGE:
      return state.update('stage', stage => stage + 1)
    case REVERT_STAGE: {
      if (action.clearAvailability) {
        return state.merge({
          stage: 0,
          additionalDatesCounter: 0,
        })
      }
      return state.update('stage', stage => (stage !== 0 ? stage - 1 : stage))
    }
    case TRY_AGAIN:
      return state.update('stage', () => 0)
    case STRIPE_ERROR:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
      })
    case HIDE_LOADING:
      return state.merge({
        displayModalType: null,
      })
    case DISABLE_INPUTS_AND_SUBMIT:
      return state.set('disableInputsAndSubmit', true)
    case TRY_POST_CHECKOUT:
      return state.merge({
        displayModalType: modalTypes.SPINNER,
        modalMessage: action.spinner_message,
      })
    case POST_CHECKOUT_SUCCESS:
      return state.merge({
        displayModalType: null,
        stage: 1,
      })
    case POST_CHECKOUT_FAILURE:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
      })
    case POST_CHECKOUT_RELOAD_ON_FAILURE:
      return state.merge({
        displayModalType: modalTypes.ERROR_DISPLAY,
        modalMessage: action.errorMessage,
        actionText: action.actionText || 'Reload',
        actionCallback: action.actionCallback || (() => null),
      })
    case DISMISS_MODAL:
      return state.set('displayModalType', null)
    case DISPLAY_MODAL:
      return state.update('displayModalType', val => (val === action.modal ? null : action.modal))
    case PASS_STRIPE_CARD_ELEMENT:
      return state.merge({ stripeCardElement: action.element })
    default:
      return state
  }
}

export default ui
