export const fetchAdyenInitialData = venueId => {
  const url = `/api-yoa/adyen/${venueId}/get_initial_data`
  return fetch(url, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(response => response.data)
}

export const submitPaymentDetails = ({ venueId, paymentData, actualId, bookingFrom, historyId, paylinkHistoryId }) => {
  const url = `/api-yoa/adyen/${venueId}/post_payment_details`

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      payment_details: paymentData,
      actual_id: actualId,
      booking_from: bookingFrom,
      history_id: historyId,
      paylink_history_id: paylinkHistoryId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.data)
}
