/* eslint react/prop-types: 0  */
import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import styled, { createGlobalStyle } from 'styled-components'
import AdyenForm from 'svr/component-lib/Widget/Payments/Adyen/AdyenForm'
import SaferpayForm from 'svr/component-lib/Widget/Payments/SaferPay/SaferpayForm'
import { getSaferpayToken } from 'svr/component-lib/Widget/Payments/SaferPay/services'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import GMapsAddress from 'widget/universal/components/GMapsAddress'
import { changeFormField, validateAllFields } from '../actions/forms'
import { toggleModalDisplay, revertStage, dismissModal } from '../actions/navigation'
import {
  submitCheckoutData,
  submitPaymentCheckoutData,
  submitPaymentCheckoutDataWithToken,
  setFreedompayVisible,
  tryPostCheckout,
  createStripeIntent,
  postCheckoutSuccess,
  postCheckoutFailure,
} from '../actions/services'
import styles from '../assets/styles/checkout'
import failStyles from '../assets/styles/confirmation'
import Banner from '../components/Banner'
import CheckoutCybersourceThreeDsPayment from './CheckoutCybersourceThreeDsPayment'
import CheckoutPayment from './CheckoutPayment'
import CheckoutPaymentBaseFields from './CheckoutPaymentBaseFields'
import CheckoutPaymentFreedompay from './CheckoutPaymentFreedompay'
import CheckoutSummary from './CheckoutSummary'
import Shift4FormCheckout from 'svr/component-lib/Widget/Payments/Shift4/Shift4Form'

class Checkout extends Component {
  constructor(props) {
    super(props)
    this.inputFields = { information: {}, payment: {} }
    this.checkoutPaymentId = 'sr-checkout-payment'
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this)
    this.onGMapsAddressUpdate = this.onGMapsAddressUpdate.bind(this)

    this.state = {}
    if (this.props.paymentType === AccountTypes.STRIPE) {
      const options = this.props.cardRequest && !this.props.connectedSetupIntents ? {} : { stripeAccount: this.props.accountId }
      this.stripe = Stripe(widgetInit.stripeKey, options)
      this.props.createStripeIntent()
    } else if (this.props.paymentType === AccountTypes.SAFERPAY) {
      this.saferpayToken = null
      this.state = { saferpayIsValid: undefined }
    } else if (this.props.paymentType === AccountTypes.ADYEN) {
      this.state = {
        adyen: {},
        adyenPaymentMethod: undefined,
        adyenIsValid: undefined,
      }
    }
    this.state.submitClicked = false
  }

  needShowCheckoutBaseFields() {
    return [AccountTypes.ADYEN, AccountTypes.SAFERPAY, AccountTypes.SHIFT_4].includes(this.props.paymentType) || this.isFreedomPayHpc()
  }

  isFreedomPayHpc() {
    return this.props.paymentType === AccountTypes.FREEDOMPAY && this.props.isFreedompayHpcEnabled
  }

  isPhoneNumberNeeded = () => this.isFreedomPayHpc()

  onSubmitClickHandler(textSpinnerMessage, commonPaymentErrors) {
    this.setState({ submitClicked: true })
    if (!this.validateBaseFields()) {
      return
    }
    if (this.props.requirePayment) {
      if (this.isFreedomPayHpc()) {
        this.props.setFreedompayVisible(true)
        return
      }
      this.submitPayment(textSpinnerMessage, commonPaymentErrors)
    } else {
      this.props.submitCheckoutData(commonPaymentErrors)
    }
  }

  validateBaseFields = () => {
    const validateFields = fieldObj =>
      _.reduce(fieldObj, (fieldMap, input, field) => _.assign(fieldMap, !input.validate() && { [field]: true }), {})

    const invalidPaymentFields = validateFields(this.inputFields.payment)
    const agreedToBookingPolicyError = this.props.agreedToBookingPolicy ? {} : { agreedToBookingPolicy: true }
    const gratuityPercentageError = !this.props.gratuityPercentage && this.props.requireGratuityCharge ? { gratuityPercentage: true } : {}
    const formErrors = _.assign({}, invalidPaymentFields, agreedToBookingPolicyError, gratuityPercentageError)
    this.props.validateAllFields(formErrors)

    if (
      !_.isEmpty(invalidPaymentFields) ||
      (this.props.paymentType === AccountTypes.SAFERPAY && !this.state.saferpayIsValid) ||
      (this.props.paymentType === AccountTypes.ADYEN && !this.state.adyenIsValid) ||
      (this.props.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX &&
        this.props.isCybersourceBillingAddressRequired &&
        !this.props.addressCountryCode) ||
      (this.isFreedomPayHpc() && !this.props.addressCountryCode && !this.props.isFreedompayRequestOnlyPostalCode)
    ) {
      this.scrollToCheckoutPaymentId()
      return false
    }
    return !(formErrors.gratuityPercentage || formErrors.agreedToBookingPolicy)
  }

  scrollToCheckoutPaymentId = () => {
    scroller.scrollTo(this.checkoutPaymentId, {
      offset: -200,
      duration: 200,
      smooth: true,
    })
  }

  submitPayment = (textSpinnerMessage, commonPaymentErrors) => {
    if (this.props.paymentType === AccountTypes.ADYEN) {
      if (this.state.adyenPaymentMethod === 'paypal') {
        const payPalData = { paymentMethod: { type: this.state.adyenPaymentMethod } }
        this.props.submitPaymentCheckoutDataWithToken(null, payPalData, textSpinnerMessage, commonPaymentErrors)
      } else {
        this.state?.adyen?.dropIn?.submit()
      }
    } else if (this.props.paymentType === AccountTypes.SAFERPAY) {
      getSaferpayToken(this.saferpayToken).then(response => {
        this.saferpayToken = response.token
        this.props.submitPaymentCheckoutDataWithToken(response.token, null, textSpinnerMessage, commonPaymentErrors)
      })
    } else if (this.props.paymentType === AccountTypes.SHIFT_4) {
      this.props.paymentEngine.submit()
    } else {
      this.props.submitPaymentCheckoutData(this.stripe, textSpinnerMessage, commonPaymentErrors)
    }
  }

  handlerOnValidatedSaferPay = isValid => {
    this.setState({ saferpayIsValid: isValid })
  }

  handlerOnValidatedAdyen = isValid => {
    this.setState({ adyenIsValid: isValid })
  }

  handlerOnAdyenChangePaymentMethod = isValid => {
    this.setState({ adyenPaymentMethod: isValid })
  }

  handlerOnSubmitAdyen = adyenState => {
    try {
      this.props.submitPaymentCheckoutDataWithToken(null, adyenState, this.props.textSpinnerMessage, this.props.commonPaymentErrors)
    } catch (error) {
      console.log('ERROR SUBMIT:', error)
    }
  }

  handlerOnSetCheckout = adyenState => {
    this.setState(prevState => ({ ...prevState, adyen: { ...(prevState?.adyen || {}), ...adyenState } }))
  }

  renderPaymentBlock() {
    if (!this.props.requirePayment) {
      return null
    }
    let component = null

    // it means we have not passed validation before we show payment block
    if (this.isFreedomPayHpc() && !this.props.freedompay.visible) {
      return null
    }

    if ([AccountTypes.SAFERPAY, AccountTypes.ADYEN].includes(this.props.paymentType) || this.isFreedomPayHpc()) {
      if (this.props.paymentType === AccountTypes.SAFERPAY) {
        component = (
          <>
            <SaferpayGlobalStylePaylink />
            <SaferpayForm
              topElementId={this.checkoutPaymentId}
              venueId={this.props.venueId}
              onValidate={this.handlerOnValidatedSaferPay}
              dataFor3Dsecure={this.props.dataFor3Dsecure}
            />
          </>
        )
      } else if (this.props.paymentType === AccountTypes.ADYEN) {
        const isValid = this.state.adyenIsValid
        component = (
          <PaymentDiv id={this.checkoutPaymentId} isValid={isValid} submitClicked={this.state.submitClicked}>
            <AdyenGlobalStylePaylink />
            <AdyenForm
              venueId={this.props.venueId}
              infoForm={{ ...styles.infoForm, ...styles.adyenDropIn }}
              onSubmit={this.handlerOnSubmitAdyen}
              onValidate={this.handlerOnValidatedAdyen}
              setAdyenCheckout={this.handlerOnSetCheckout}
              onChangePaymentMethod={this.handlerOnAdyenChangePaymentMethod}
              dataFor3Dsecure={this.props.dataFor3Dsecure}
              onSuccess={this.props.postCheckoutSuccess}
              onFailure={this.props.postCheckoutFailure}
              amount={this.props.amount}
            />
          </PaymentDiv>
        )
      } else if (this.isFreedomPayHpc()) {
        component = (
          <CheckoutPaymentFreedompay
            topElementId={this.checkoutPaymentId}
            textSpinnerMessage={this.props.textSpinnerMessage}
            commonPaymentErrors={this.props.commonPaymentErrors}
          />
        )
      }
    } else if (this.props.paymentType === AccountTypes.SHIFT_4) {
      const onShift4Success = (token, cardData) =>
        this.props.submitPaymentCheckoutDataWithToken(token, cardData, this.props.textSpinnerMessage, this.props.commonPaymentErrors)
      component = <Shift4FormCheckout onSuccess={onShift4Success} />
    } else if (this.props.cybersourceThreeDsPaymentStep) {
      component = <CheckoutCybersourceThreeDsPayment />
    } else {
      component = (
        <CheckoutPayment
          stripe={this.stripe || null}
          stripeIntentClientSecret={this.props.stripeIntentClientSecret}
          topElementId={this.checkoutPaymentId}
          paymentFields={this.inputFields.payment}
          submitClicked={this.state.submitClicked}
          isCybersourceBillingAddressRequired={this.props.isCybersourceBillingAddressRequired}
        />
      )
    }
    return (
      <>
        {!this.isFreedomPayHpc() ? (
          <div style={[styles.labelWrapper, { margin: 0, height: 'auto' }]}>
            <span style={[styles.sectionLabel, { color: this.props.fontsColorPrimary }]}>
              {this.props.textPaylinkPageCreditCardDetailsSubheader}
            </span>
          </div>
        ) : null}
        {component}
      </>
    )
  }

  onGMapsAddressUpdate(address, place) {
    const address1 = []
    let city
    let locality
    let administrativeArea
    let postalCode
    let countryCode
    if (!place) {
      this.setState({ address: '' })
      this.props.changeFormField('address1', null)
      this.props.changeFormField('city', null)
      this.props.changeFormField('locality', null)
      this.props.changeFormField('administrativeArea', null)
      this.props.changeFormField('countryCode', null)
      this.props.changeFormField('postalCode', null)
      return
    }
    // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
    _.each(place.address_components, addressComponent => {
      if (['street_address', 'street_number', 'route'].some(r => addressComponent.types.includes(r))) {
        address1.push(addressComponent.long_name)
      }
      if (addressComponent.types.includes('locality')) {
        locality = addressComponent.long_name
      }
      if (!locality && addressComponent.types.includes('administrative_area_level_1')) {
        locality = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_3')) {
        city = addressComponent.long_name
      }
      if (!city && addressComponent.types.includes('administrative_area_level_2')) {
        city = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        administrativeArea = addressComponent.short_name
      }
      if (addressComponent.types.includes('country')) {
        countryCode = addressComponent.short_name
      }
      if (addressComponent.types.includes('postal_code')) {
        postalCode = addressComponent.long_name
      }
    })
    this.setState({
      address,
    })

    this.props.changeFormField('address1', address1.join(' '))
    this.props.changeFormField('city', city || '')
    this.props.changeFormField('locality', locality || '')
    this.props.changeFormField('administrativeArea', administrativeArea || '')
    this.props.changeFormField('countryCode', countryCode || '')
    this.props.changeFormField('postalCode', postalCode || '')
  }

  render() {
    const {
      venueName,
      venueLogo,
      colorWidgetBackground,
      fontsColorPrimary,
      fontsColorLinks,
      revertStage,
      colorSummaryBar,
      fontsColorSummaryBar,
      validLink,
      mediaUrl,
      colorButtonBackground,
      colorButtonText,
      termsPolicyHolderName,
      termsOfServiceUrl,
      termsOfServiceText,
      textCustomPolicyHolderName,
      textCustomPrivacyPolicyLink,
      textCustomPrivacyPolicyLinkLabel,
      textCustomGdprPolicyLink,
      textCustomGdprPolicyLinkLabel,
      isWidgetWhitelabeled,
      textPaylinkPageCreditCardDetailsHeader,
      textCheckoutSubmitButton,
      textWidgetPoweredByLabel,
      textWidgetPolicyDisclaimer,
      textPaylinkPageContactInformation,
      textPaylinkPageExpiredLink,
      textSpinnerMessage,
      textPaylinkAnd,
      commonPaymentErrors,
    } = this.props
    if (!validLink || widgetInit.actualInfo.is_status_canceled) {
      const iconAdjust = {
        color: '#fff',
        fontSize: '20px',
        marginLeft: '1px',
        marginTop: '40px',
      }

      return (
        <div style={[styles.checkoutWrapper, { backgroundColor: colorWidgetBackground }]}>
          <Banner
            name={venueName}
            logo={venueLogo}
            onBackClick={revertStage}
            colorSummaryBar={colorSummaryBar}
            fontsColorSummaryBar={fontsColorSummaryBar}
          />
          <div style={[failStyles.bodyText, { width: '90%', margin: '0 auto 30px', color: fontsColorPrimary }]}>
            <div>
              <span className="fa-stack fa-lg" style={{ color: fontsColorPrimary, fontSize: '50px' }}>
                <i style={failStyles.fontIcon} className="fa fa-switch-circle fa-stack-1x" />
                <i style={[failStyles.fontIcon, iconAdjust]} className="fa fa-times fa-stack-2x" />
              </span>
            </div>
            <span style={failStyles.largeConfirmText}>{textPaylinkPageExpiredLink}</span>
            <span
              style={[failStyles.confirmation, { fontSize: '16px' }]}
              dangerouslySetInnerHTML={{
                __html: textPaylinkPageContactInformation,
              }}
            />
          </div>
        </div>
      )
    }

    if (widgetInit.successBypass || widgetInit.errorDisplay) {
      return null
    }

    const buildTermsAnchorTag = (href, text) => (
      <a style={[styles.link, { color: fontsColorLinks }]} target="_blank" rel="noreferrer noopener" href={href} key={href + text}>
        {text}
      </a>
    )
    const groupTermsOfServiceAnchor = buildTermsAnchorTag(termsOfServiceUrl, termsOfServiceText)
    const gdprAnchor =
      !_.isEmpty(textCustomGdprPolicyLinkLabel) && buildTermsAnchorTag(textCustomGdprPolicyLink, textCustomGdprPolicyLinkLabel)
    const isSevenRoomsGdprPolicy =
      !textCustomGdprPolicyLink || textCustomGdprPolicyLink.startsWith('https://www.sevenrooms.com/gdpr-policy/')
    const hasCustomPrivacyPolicy = !_.isEmpty(textCustomPrivacyPolicyLink)
    const customPrivacyPolicyAnchor =
      hasCustomPrivacyPolicy && buildTermsAnchorTag(textCustomPrivacyPolicyLink, textCustomPrivacyPolicyLinkLabel)
    return (
      <div style={[styles.checkoutWrapper, { backgroundColor: colorWidgetBackground }]}>
        <Banner
          name={venueName}
          logo={venueLogo}
          onBackClick={revertStage}
          colorSummaryBar={colorSummaryBar}
          fontsColorSummaryBar={fontsColorSummaryBar}
        />
        <h3 style={styles.summaryHeader}>{textPaylinkPageCreditCardDetailsHeader}</h3>
        {this.needShowCheckoutBaseFields() ? (
          <CheckoutPaymentBaseFields
            isPhoneNeeded={this.isPhoneNumberNeeded()}
            paymentFields={this.inputFields.payment}
            disabled={this.props.disableInputsAndSubmit}
          />
        ) : null}
        {this.isFreedomPayHpc() && !this.props.isFreedompayRequestOnlyPostalCode ? (
          <div style={styles.sectionWrapper}>
            <div style={styles.infoForm}>
              <GMapsAddress onAddressUpdate={this.onGMapsAddressUpdate} submitClicked={this.state.disableInputsAndSubmit} />
            </div>
          </div>
        ) : null}
        {/* for FREEDOMPAY we place payment block in the bottom */}
        {!this.isFreedomPayHpc() ? <div style={styles.formWrapper}>{this.renderPaymentBlock()}</div> : null}
        <div style={styles.formWrapper}>
          <CheckoutSummary disabled={this.props.disableInputsAndSubmit} />
        </div>
        <div style={[styles.footerText, { color: fontsColorPrimary }]}>
          {/* See WB-1546 for terms/policy formatting/language */}
          {`${textWidgetPolicyDisclaimer} ${termsPolicyHolderName} `}
          {/* eslint-disable-next-line no-nested-ternary */}
          {hasCustomPrivacyPolicy && isSevenRoomsGdprPolicy
            ? [
                groupTermsOfServiceAnchor,
                gdprAnchor && ` ${textPaylinkAnd} `,
                gdprAnchor,
                `  ${textPaylinkAnd} ${textCustomPolicyHolderName} `,
                customPrivacyPolicyAnchor,
              ]
            : hasCustomPrivacyPolicy
            ? [
                groupTermsOfServiceAnchor,
                ` ${textPaylinkAnd} ${textCustomPolicyHolderName} `,
                customPrivacyPolicyAnchor,
                gdprAnchor && ` ${textPaylinkAnd} `,
                gdprAnchor,
              ]
            : [groupTermsOfServiceAnchor, gdprAnchor && ` ${textPaylinkAnd} `, gdprAnchor]}
        </div>

        <div style={styles.buttonWrapper}>
          <div
            style={[
              styles.submitButton,
              {
                backgroundColor: colorButtonBackground,
                color: colorButtonText,
                pointerEvents: this.props.disableInputsAndSubmit ? 'none' : 'auto',
              },
            ]}
            onClick={() => {
              this.onSubmitClickHandler(textSpinnerMessage, commonPaymentErrors)
            }}
          >
            {textCheckoutSubmitButton}
          </div>
        </div>

        {this.isFreedomPayHpc() ? <div style={styles.formWrapper}>{this.renderPaymentBlock()}</div> : null}
        {isWidgetWhitelabeled !== 'true' && (
          <div style={styles.paylinkFooter}>
            <p>{textWidgetPoweredByLabel}</p>
            <p>
              <img style={{ height: '25px' }} src={`${mediaUrl}images/SevenRoomsTitle.svg`} />
            </p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  // TODO handled by billing info
  const requirePayment = true
  const languageStrings = selectLanguageStrings(state)
  const { selectedLanguage } = state.languages
  return {
    requirePayment,
    freedompay: state.payment.freedompay,
    disableInputsAndSubmit: state.ui.get('disableInputsAndSubmit'),
    agreedToBookingPolicy: state.formFields.get('agreedToBookingPolicy'),
    gratuityPercentage: state.formFields.get('gratuityPercentage'),
    addressCountryCode: state.formFields.get('countryCode'),
    requireGratuityCharge: state.billingInfo.require_gratuity_charge,
    mediaUrl: state.widgetSettings.mediaUrl,
    selectedLanguage,
    // colors
    venueId: state.venueInfo.id,
    paymentType: state.venueInfo.paymentType,
    currency: state.venueInfo.currencyCode,
    countryCode: state.venueInfo.countryCode,
    isFreedompayHpcEnabled: state.venueInfo.isFreedompayHpcEnabled,
    isFreedompayRequestOnlyPostalCode: state.venueInfo.isFreedompayRequestOnlyPostalCode,
    isCybersourceBillingAddressRequired: state.venueInfo.isCybersourceBillingAddressRequired,
    stripeObject: state.commonPayment.stripe,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorButtonBackground: state.venueInfo.buttonBgColor,
    colorButtonText: state.venueInfo.buttonTextColor,
    colorPrimary: state.venueInfo.venueColor,
    colorSummaryBar: state.venueInfo.venueColor,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorLinks: state.widgetSettings.fontsColorLinks,
    fontsColorSummaryBar: state.venueInfo.venueTextColor,
    venueLogo: state.venueInfo.venueLogo,
    venueName: state.venueInfo.name,
    validLink: state.billingInfo.valid_link,
    phone: state.venueInfo.phoneNumber,
    accountId: state.venueInfo.accountId,
    connectedSetupIntents: state.venueInfo.connectedSetupIntents,
    amount: state.billingInfo.amount,
    cardRequest: state.billingInfo.card_request,
    termsPolicyHolderName: state.widgetSettings.termsPolicyHolderName,
    termsOfServiceUrl: state.widgetSettings.termsOfServiceUrl,
    privacyPolicyUrl: state.widgetSettings.privacyPolicyUrl,
    textCustomPolicyHolderName: state.widgetSettings.textCustomPolicyHolderName,
    textCustomPrivacyPolicyLink: state.widgetSettings.textCustomPrivacyPolicyLink,
    textCustomGdprPolicyLink: state.widgetSettings.textCustomGdprPolicyLink,
    isWidgetWhitelabeled: state.widgetSettings.isWidgetWhitelabeled,
    // text
    textPaylinkPageCreditCardDetailsSubheader: languageStrings.textPaylinkPageCreditCardDetailsSubheader,
    textPaylinkPageCreditCardDetailsHeader: languageStrings.textPaylinkPageCreditCardDetailsHeader,
    textCheckoutSubmitButton: languageStrings.textCheckoutSubmitButton,
    textWidgetPoweredByLabel: languageStrings.textWidgetPoweredByLabel,
    textWidgetPolicyDisclaimer: languageStrings.textWidgetPolicyDisclaimer,
    textPaylinkPageContactInformation: state.venueInfo.phoneNumber
      ? `<br /><br />${languageStrings.textPaylinkPageContactInformation.replace('<venue_phone_number>', state.venueInfo.phoneNumber)}`
      : '',
    textCommonAtText: languageStrings.textCommonAtText,
    privacyPolicyText: languageStrings.textCustomPrivacyPolicyLinkLabel,
    textCustomPrivacyPolicyLinkLabel: languageStrings.textCustomPrivacyPolicyLinkLabel,
    textCustomGdprPolicyLinkLabel: languageStrings.textCustomGdprPolicyLinkLabel,
    termsOfServiceText: languageStrings.textWidgetTermsOfServiceLinkLabel,
    textPaylinkPageExpiredLink: languageStrings.textPaylinkPageExpiredLink,
    textSpinnerMessage: languageStrings.textPaylinkPageProcessingRequest,
    textPaylinkAnd: languageStrings.textCommonAnd,
    commonPaymentErrors: {
      textCommonPaymentErrorUnableToProcess: languageStrings.textCommonPaymentErrorUnableToProcess,
      textCommonPaymentErrorConnectionError: languageStrings.textCommonPaymentErrorConnectionError,
      textCommonPaymentErrorProcessingError: languageStrings.textCommonPaymentErrorProcessingError,
      textCommonPaymentErrorPaymentRejectedError: languageStrings.textCommonPaymentErrorPaymentRejectedError,
      textCommonPaymentErrorUnexpectedError: languageStrings.textCommonPaymentErrorUnexpectedError,
      stripeZhHkStrings:
        selectedLanguage === 'zh_HK'
          ? {
              incomplete_cvc: languageStrings.testCommonPaymentErrorIncompleteSecCode,
              incomplete_number: languageStrings.textWidgetErrorsCardIncomplete,
              incomplete_expiry: languageStrings.textCommonPaymentErrorExpiredCard,
              invalid_number: languageStrings.textWidgetErrorsCardInvalid,
              incomplete_zip: languageStrings.testcommonPaymentErrorIncompletePostalCode,
              invalid_expiry_year_past: languageStrings.textCommonPaymentErrorExpiredCard,
            }
          : {},
    },
    cybersourceThreeDsPaymentStep: state.cybersourceThreeDsPayment.step,
    dataFor3Dsecure: state.commonPayment.dataFor3Dsecure,
    dataForPendingPayment: state.commonPayment.pendingPayment,
    paymentEngine: state.commonPayment.paymentEngine,
    stripeIntentClientSecret: state.commonPayment.stripeIntentClientSecret,
  }
}
const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  revertStage: () => {
    dispatch(revertStage())
  },
  submitCheckoutData: commonPaymentErrors => {
    dispatch(submitCheckoutData(commonPaymentErrors))
  },
  submitPaymentCheckoutData: (stripe, textSpinnerMessage, commonPaymentErrors) => {
    dispatch(submitPaymentCheckoutData(stripe, textSpinnerMessage, commonPaymentErrors))
  },
  submitPaymentCheckoutDataWithToken: (token, cardData, textSpinnerMessage, commonPaymentErrors) => {
    dispatch(submitPaymentCheckoutDataWithToken(token, cardData, textSpinnerMessage, commonPaymentErrors))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  dismissModal: () => {
    dispatch(dismissModal())
  },
  tryPostCheckout: textSpinnerMessage => {
    dispatch(tryPostCheckout(textSpinnerMessage))
  },
  postCheckoutFailure: errorMessage => {
    dispatch(postCheckoutFailure(errorMessage))
  },
  postCheckoutSuccess: () => dispatch(postCheckoutSuccess()),
  validateAllFields: formErrors => {
    dispatch(validateAllFields(formErrors))
  },
  setFreedompayVisible: visible => {
    dispatch(setFreedompayVisible(visible))
  },
  createStripeIntent: () => {
    dispatch(createStripeIntent())
  },
})

const PaymentDiv = styled.div`
  width: 89.33333%;
  margin: 0 auto;
  border-radius: 8px;
  border-width: 1px;
  border-color: red;
  border-style: ${props => (!props.isValid && props.submitClicked ? 'solid' : 'hidden')};
`

const AdyenGlobalStylePaylink = createGlobalStyle`
  #sr-checkout-payment {
    font-size: 14px;
  }
  .adyen-checkout__label__text {
      padding: 10px 0px 10px 10px ;
      font-size: 14px;
  }
  .adyen-checkout__input-wrapper {
      margin: 10px;
  }
  .adyen-checkout__field {
      margin-bottom: 0;
  }
  .adyen-checkout__issuer-list {
      padding: 1px;
  }
`
const SaferpayGlobalStylePaylink = createGlobalStyle`
  #sr-checkout-payment {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px 15px;
    margin: 10px 0 0 10px;
  }

  #fields-holder-name, #fields-card-number, #fields-expiration, #fields-cvc {
      height: 40px;
      width: 355px;
      margin-bottom: 5px;
  }
`

Checkout = connect(mapStateToProps, mapDispatchToProps)(Radium(Checkout))

export default Checkout
